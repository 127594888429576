import React, { Component, useEffect } from "react";
import LoginHeader from "./Components/LoginHeader";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert";
import jwtEncode from "jwt-encode";
import { jwtDecode } from "jwt-decode";
import { fetchLogoData } from './ApiUtils';
import { RenderLoginHeader } from './RenderLoginHeader';
import { RenderLoginButton } from './RenderLoginButton';
import { RenderLoginCard } from './RenderLoginCard';
import { RenderLoader } from './RenderLoader';
require("dotenv").config();
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirect: false,
      loading: false,
      isCheckingToken: true,
    };
  }
showSwalError = (message) => {
    Swal({
      text: message,
      icon: "error",
      button: "OK",
    });
  };

storeLoginData = (data, email) => {
  const keysToStore = {
    customer_id: data.MemberID,
    LoginName: data.LoginName,
    LoginEmail: email,
    Logo: data.Logo,
    UserID: data.UserID,
    CustomerType: data.CustomerType,
    auth_token: data.auth_token,
    CuDefaultPercentage: data.CuDefaultPercentage,
    CuPdfFileName: data.CuCouponFileName,
    CuDefaultBranch: data.CuDefaultBranch,
    CompanyName: data.CompanyName,
    BankAddress: data.BankAddress,
    BankAddress2: data.BankAddress2,
    BankState: data.BankState,
    BankZip: data.BankZip,
    BankCity: data.BankCity,
    TempPasswordStatus: data.TempPasswordStatus,
  };
    Object.entries(keysToStore).forEach(([key, value]) =>
      localStorage.setItem(key, value)
    );
};

redirectToLogin = () => {
  this.props.history.push("/login");
  this.setState({ isCheckingToken: false });
};

  emailValue(e) {
    this.setState({ email: e.target.value });
  }

  passwordValue(e) {
    this.setState({ password: e.target.value });
  }

renderInputField(label, type, placeholder, value, onChange) {
  return (
    <div>
      <label className="focus-label">{label}</label>
      <div className="form-group form-focus focused">
        <input type={type} className="form-control" placeholder={placeholder} value={value} onChange={onChange}/>
      </div>
    </div>
  );
}

fetchLogo = async () => {
  try {
     const logo = await fetchLogoData(1);
    localStorage.setItem("PifLogo", logo);
  } catch (error) {
    console.error("Failed to fetch the logo");
  }
};

validateInputs = () => {
    if (!this.state.email) {
      this.showSwalError("Please enter email.");
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)) {
      this.showSwalError("Please enter a valid email.");
      return false;
    }
    if (!this.state.password) {
      this.showSwalError("Please enter password.");
      return false;
    }
    return true;
  };

  userlogin = (e) => {
    e.preventDefault();
    if (!this.validateInputs()) return;
    const clientData = {
      email: this.state.email,
      password: this.state.password,
      token: "",
    };
    var token = jwtEncode(
      {
        email: this.state.email,
        exp: Math.floor(Date.now() / 1000) + 3 * 60 * 60,
      },
      "test"
    );
    this.setState({ loading: true });
    setTimeout(() => 
    {
        this.fetchLogo();
      console.log("after login auth token");
      axios.post(process.env.REACT_APP_BASE_URL + `/authenticationAPI/Login`,clientData)
        .then((res) => 
        {
          this.setState({ loading: false });
          if (res.data.Status) 
          {
              localStorage.setItem("UrlCustomerType", res.data.CustomerType === "None" ? "None" : null);
             this.storeLoginData(res.data, this.state.email);
            localStorage.setItem("DealerId", res.data.UserID);
            var tokenData = {
              token: token,
              user_member_id: res.data.MemberID,
              auth_token: localStorage.getItem("auth_token"),
            };
            axios.post(process.env.REACT_APP_BASE_URL +`/authenticationAPI/UpdateToken`,tokenData)
              .then((res1) => 
              {
                if (res1.data.status) 
                {
                  console.log("Token updated successfully.");
                }
              })
              .catch((error) => 
              {
                console.error("Error updating token:", error);
              });
            localStorage.setItem("token", token);
            this.setState({ redirect: true });
          }
           else 
           {
              this.showSwalError(res.data.Message);
    
          }
        });
    }, 2000);
  };

extractUrlParams() {
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get("token");
  const UrlCustomerType = params.get("customer_type");
  return { tokenFromUrl, UrlCustomerType };
}

handleExpiredSession() {
  Swal({
    text: "Your session has expired. Please log in again",
    icon: "error",
    button: "OK",
  }).then(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete("token");
    window.location.href = url.toString();
  });
  this.redirectToLogin();
}

processToken(tokenFromUrl) {
  const decoded = jwtDecode(tokenFromUrl);
  if (decoded.exp < Math.floor(Date.now() / 1000)) {
    this.handleExpiredSession();
    return;
  }
  this.loginWithToken(tokenFromUrl, decoded.email);
}

componentDidMount() {
  this.initializeLoginProcess();
}

initializeLoginProcess() {
  const { tokenFromUrl, UrlCustomerType } = this.extractUrlParams();
  if (UrlCustomerType && tokenFromUrl) {
    localStorage.setItem("UrlCustomerType", UrlCustomerType);
  }
  if (!tokenFromUrl) {
    this.redirectToLogin();
    return;
  }
  this.processToken(tokenFromUrl);
}

loginWithToken(tokenFromUrl, email) {
  const clientData = { email: "", password: "", token: tokenFromUrl };
  this.setState({ loading: true });
  this.fetchLogo();
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/authenticationAPI/Login`, clientData)
    .then((res) => {
      this.setState({ loading: false });
      if (res.data.Status) {
        this.storeLoginData(res.data, email);
        localStorage.setItem("DealerId", res.data.UserID);
        localStorage.setItem("token", res.data.token);
        this.props.history.push("/dashboard");
      } else {
        this.showSwalError(res.data.Message);
        this.redirectToLogin();
      }
    })
    .catch(() => {
      this.redirectToLogin();
    });
}
 renderLoginForm() {
  return (
    <form action="" method="POST" style={{ width: "80%", margin: "0 auto" }}>
      {this.renderInputField("Email", "email", "Email", this.state.email, (e) => this.emailValue(e))}
      {this.renderInputField("Password", "password", "Password", this.state.password, (e) => this.passwordValue(e))}
      <RenderLoginButton onClick={this.userlogin} />
    </form>
  );
}

renderContent() {
  return (
    <>
      <RenderLoginCard/>
      <div className="container-fluid" style={{ background: "#cccccc38" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="account-content">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-6 col-lg-6 login-right login-right bg-white mt-5 mb-5">
                  <RenderLoginHeader/>
                  {this.renderLoginForm()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
  render() 
  {
    const { t } = this.props;
    if (this.state.redirect) return <Redirect to="/dashboard" />;
    return (
      <div class="main-wrapper">
         {this.state.loading && <RenderLoader/>}
          <LoginHeader />
          <div className="content pb-0">{this.renderContent()}</div>
      </div>
    );
  }
}

export default Login;
