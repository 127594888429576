import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import axios from "axios";
import { fetchLogoData } from '../ApiUtils';
const settingLanguage = (lang) => i18n.changeLanguage(lang);

class LoginHeader extends React.Component
{
constructor(props) 
{
    super(props);
    this.state = {
      piflogo: null, 
      loading: true, 
      error: null 
    };
  }

  componentDidMount() 
  {
    this.fetchUser();
  }
 
 fetchUser = async () => {
  try {
    const logo = await fetchLogoData(1); 
    if (logo) {
      this.setState({
        piflogo: logo, 
        loading: false,
      });
    } else {
      this.setState({
        error: 'Error fetching user data.',
        loading: false,
      });
    }
  } catch (error) {
    this.setState({
      error: 'Error fetching user data.',
      loading: false,
    });
    console.error('Error fetching user data:', error);
  }
};
    renderLogo = (logoSrc,link,classdata,styleData,styleData1) => {

       const defaultStyles = {
    color: 'red',
    fontSize: '24px',
    fontWeight: 'bold',
    ...styleData,  
  };

    return (
      <a href={link} className={classdata} style={defaultStyles}>
        <img src={logoSrc} style={{...styleData1}} />
      </a>
    );
  };


renderAdminLinks = (pifurl, token, classdata,classdata1) => {
    return (
      <>
        <a
          title="admin dashboard"
          target="_blank"
          href={`${pifurl}/authentication/sign-in?token=${token}`}
          className={`${classdata}`}
          style={{ color: "rgb(0, 47, 108)", padding: "8px 16px" }}
        >
          <div className="icon-combo">
            <i className="fas fa-user"></i>
            <i className="fas fa-cog"></i>
          </div>
        </a>
        <a
          title="logout"
          href="/logout"
          className={`${classdata1}`}
          style={{
            color: "#002f6c",
            fontSize: "26px",
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          <i className="fas fa-sign-out-alt"></i>
        </a>
      </>
    );
  };

renderDesktopHeader = (logo, piflogo, pifurl, token, LoginEmail) => (
  <div className="col-md-12 col-12 hidden-sm hidden-xs">
    <div className="navbar-header" style={{ display: "flex" }}>
      {logo &&
        this.renderLogo(
          logo,
          '/dashboard',
          'hidden-xs hidden-sm navbar-brand logo',
          { display: 'flex' },
          { width: '100%', height: '100%', objectFit: 'contain', maxWidth:'180px', 'maxHeight':'65px' }
        )}
      {this.renderLogo(
        piflogo,
        '/dashboard',
        'hidden-xs hidden-sm navbar-brand logo',
        { display: 'flex', margin: '0 auto' },
        { width: 'unset!important', height: 'auto', objectFit: 'contain', maxWidth:'180px', 'maxHeight':'65px' }
      )}
      {LoginEmail &&
        this.renderAdminLinks(
          pifurl,
          token,
          'hidden-xs hidden-sm navbar-brand',
          'hidden-xs hidden-sm navbar-brand'
        )}
    </div>
  </div>
);

renderMobileHeader = (logo, piflogo, pifurl, token, LoginEmail) => (
  <>
    <div className="col-md-6 col-6 hidden-lg hidden-md test">
      <center>
        {this.renderLogo(piflogo, '/', 'hidden-lg hidden-md', {}, { width: '100%', height: '100%', objectFit: 'contain', maxWidth:'180px', 'maxHeight':'65px' })}
      </center>
    </div>
    <div className="col-md-2 col-4 hidden-lg hidden-md">
      <div className="navbar-header" style={{ display: 'flex' }}>
        <a className="mt-5" id="mobile_btn" href="javascript:void(0);"></a>
        {logo &&
          this.renderLogo(
            logo,
            '/dashboard',
            'hidden-xs hidden-sm navbar-brand logo',
            { display: 'flex' },
            { width: '100%', height: '100%', objectFit: 'contain', maxWidth:'180px', 'maxHeight':'65px' }
          )}
        {this.renderLogo(
          localStorage.getItem('PifLogo'),
          '/',
          'hidden-xs hidden-sm navbar-brand logo',
          {},
          { width: '100%', height: '100%', objectFit: 'contain', maxWidth:'180px', 'maxHeight':'65px' }
        )}
        {LoginEmail &&
          this.renderAdminLinks(
            pifurl,
            token,
            'mobile-user navbar-brand',
            'navbar-brand mobile-sign-in'
          )}
      </div>
    </div>
  </>
);

  
    render(){
        const { t } = this.props;
        const LoginEmail = localStorage.getItem('LoginEmail');
        var pifurl1=process.env.REACT_APP_ADMIN_URL;
        var pifurl=pifurl1.replace(/"/g, '');
        const logo = localStorage.getItem("Logo");
        const { piflogo } = this.state;
        const token = localStorage.getItem('token');
 return (
<header className="header"> 
  <nav className="navbar navbar-expand-lg header-nav " style={{'background':'#e6e6e6!important'}}>
    <div className="row w-100" style={{'marginLeft':'0px','marginRight':'0px'}}>
      
{this.renderDesktopHeader(logo, piflogo, pifurl, token, LoginEmail)}
       <div class="col-2"></div>
          {this.renderMobileHeader(logo, piflogo, pifurl, token, LoginEmail)}

</div>
</nav>
</header>
        )
    }

}

export default withTranslation()(LoginHeader);