import React from "react";
import Swal from "sweetalert";
import axios from "axios";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
require("dotenv").config();

class CouponId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.inputRefs = {};
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const token = queryParams.get("token");

    if (!this.validateToken(token)) return;

    this.fetchQuoteDetails(id, token);
  }

  validateToken = (token) => {
    if (!token || token === "null") {
      Swal({
        text: "Token is required.",
        icon: "error",
        button: "OK",
      });
      return false;
    }
    return true;
  };

  fetchQuoteDetails = async (id, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/authenticationAPI/GetQuoteDetailsByQuoteId?quote_id=${id}`
      );
      const data = response.data[0];

      if (token !== data["CouponToken"]) {
        Swal({
          text: "Token does not match.",
          icon: "error",
          button: "OK",
        });
        return;
      }

      this.prepareAgreementData(data);
    } catch (error) {
      console.error("Error fetching quote details:", error);
      Swal({
        text: "Failed to fetch quote details.",
        icon: "error",
        button: "OK",
      });
    }
  };

  prepareAgreementData = (data) => {
    const {
      ApplicationID: quoteId,
      PreApprovedStatus: preApprovedStatus,
      CuReferral: cuReferral,
      Branch: branch,
      FirstNames: name,
      EmailAddresses: email,
      Phone: phone,
      CuCouponFileName: cuCouponFileName,
    } = data;

    const amazonStatus = 1;
    this.addAgreementInfo(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      parseInt(amazonStatus),
      quoteId,
      name,
      email,
      phone,
      cuReferral,
      branch,
      parseInt(preApprovedStatus),
      cuCouponFileName
    );
  };

  addTextToPage = async (page, texts, font) => {
    texts.forEach(({ text, x, y, size }) => {
      page.drawText(text, {
        x,
        y,
        size,
        font,
        color: rgb(0, 0, 0),
      });
    });
  };

  getStatus = (status, condition, coordinates, fallback = { text: ".", x: 0, y: 0, size: 0 }) => {
    return status === condition ? { text: ".", ...coordinates } : fallback;
  };

  getReceiveAmazonStatus = (receiveAmazonStatus) => {
    return this.getStatus(receiveAmazonStatus, 0, { x: 383, y: 85, size: 80 }, { x: 423, y: 85, size: 80 });
  };

  getPreApprovedStatus = (preApprovedStatus) => {
    return this.getStatus(preApprovedStatus, 1, { x: 181, y: 29, size: 50 });
  };

  addAgreementInfo = async (
    dealerName,
    terms,
    date,
    year,
    make,
    model,
    salesRep,
    signConfirm,
    receiveAmazonStatus,
    credit,
    customerName,
    customerEmail,
    cell,
    branch,
    cuReferral,
    preApprovedStatus,
    cuCouponFileName
  ) => {
    const filePath = this.getFilePath(cuCouponFileName);
    const pdfBytes = await this.modifyPdf(filePath, {
      dealerName,
      terms,
      date,
      year,
      make,
      model,
      salesRep,
      signConfirm,
      receiveAmazonStatus,
      credit,
      customerName,
      customerEmail,
      cell,
      branch,
      cuReferral,
      preApprovedStatus,
    });
    this.downloadPdf(pdfBytes, customerName, credit);
  };

  getFilePath = (cuCouponFileName) => {
    if (cuCouponFileName && cuCouponFileName !== "undefined" && cuCouponFileName !== "") {
      const bucketName = "pifapp";
      const region = "us-east-2";
      const baseUrl = `https://${bucketName}.s3.${region}.amazonaws.com/coupons/`;
      return `${baseUrl}${cuCouponFileName}.pdf`;
    }
    return `${window.location.origin}/image.pdf`;
  };

  modifyPdf = async (filePath, data) => {
    const response = await fetch(filePath);
    const arrayBuffer = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const page = pdfDoc.getPages()[0];

    const borrowerInfo = [
      { text: data.dealerName, x: 220, y: 150, size: 8 },
      { text: data.terms, x: 320, y: 170, size: 8 },
      { text: data.date, x: 510, y: 152, size: 8 },
      { text: data.year, x: 210, y: 130, size: 8 },
      { text: data.make, x: 290, y: 130, size: 8 },
      { text: data.model, x: 340, y: 130, size: 8 },
      { text: data.salesRep, x: 280, y: 115, size: 8 },
      { text: data.signConfirm, x: 420, y: 115, size: 8 },
      this.getReceiveAmazonStatus(data.receiveAmazonStatus),
      this.getPreApprovedStatus(data.preApprovedStatus),
      { text: data.credit, x: 520, y: 70, size: 8 },
      { text: data.customerName, x: 260, y: 70, size: 8 },
      { text: data.customerEmail, x: 360, y: 50, size: 8 },
      { text: data.cell, x: 240, y: 50, size: 8 },
      { text: data.branch, x: 340, y: 30, size: 8 },
      { text: data.cuReferral, x: 480, y: 30, size: 8 },
    ];
    await this.addTextToPage(page, borrowerInfo, font);

    return pdfDoc.save();
  };

  downloadPdf = (pdfBytes, customerName, credit) => {
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${customerName}_${credit}.pdf`;
    link.click();

    URL.revokeObjectURL(url);
  };

  render() {
    return (
      <>
        <div />
        <style>{`@import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");`}</style>
      </>
    );
  }
}

export default CouponId;
