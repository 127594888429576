import axios from 'axios';

// Reusable function to fetch the logo
export const fetchLogoData = async (userId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/authenticationAPI/getlogo`, {
      params: { user_id: userId },
    });
    return response.data[0]?.Logo; // Return the logo
  } catch (error) {
    console.error('Error fetching logo:', error);
    return null; // Return null if error occurs
  }
};
