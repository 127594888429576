import React from 'react';
export const RenderLoader = () => (
    <>
      <div style={{color: "black",position: "fixed",top: "15%",left: "0",fontSize: "22px",width: "100%",height: "100%",zIndex: "9999",display: "flex",justifyContent: "center",alignItems: "center"}}>Loading...</div>
      <div className="loader-overlay">
        <div className="spinner-square">
          <div className="square-1 square"></div>
          <div className="square-2 square"></div>
          <div className="square-3 square"></div>
        </div>
      </div>
    </>
  );
