import React from "react";

export const RenderLoginHeader = () => (
  <div className="login-header">
    <h3 className="login-title color-cus1" style={{ textAlign: "center" }}>
      <span className="font-weight-bold">Login</span>
    </h3>
  </div>
);

 